import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import {FontLoader} from "three/addons";
import {TextGeometry} from "three/addons";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'


/**
 * Base
 */

// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/8.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace
/**
 * Font
 */
//Loader
const material = new THREE.MeshMatcapMaterial()
material.matcap = matcapTexture
// material.flatShading = false
const fontLoader = new FontLoader()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font)=>{
        const geometry = new TextGeometry(
            'I eat food',
            {
                font: font,
                size: 0.5,
                height: 0.1,
                curveSegments: 16,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 9
            }
        )
        geometry.center()
        geometry.computeVertexNormals();
        geometry.normalizeNormals()
        const text = new THREE.Mesh(geometry, material)
        text.geometry.computeBoundingBox()
        scene.add(text)
    }
)


/**
 * Object
 */
const donutGeometry = new THREE.TorusGeometry(0.3,0.15,20,45)
console.time('donut')
for (let i =0; i<1000;i++)
{
    const donutMesh = new THREE.Mesh(donutGeometry, material)
    donutMesh.position.x = (Math.random()-0.5)*10
    donutMesh.position.y = (Math.random()-0.5)*10
    donutMesh.position.z = (Math.random()-0.5)*10
    donutMesh.rotation.x = Math.random() * Math.PI
    donutMesh.rotation.y = Math.random() * Math.PI
    const scale  = Math.random()
    donutMesh.scale.set(scale,scale,scale)
    scene.add(donutMesh)
}
console.timeEnd('donut')
/**
 * Axes helper
 */
// const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()